import React from 'react';

class EASonchainChooseName extends React.Component {
  handleRadioChange = (fieldName) => {
    if (this.props.setEASattestationName) {
      this.props.setEASattestationName(fieldName);
    }
  };

  formatCamelCaseString(str) {
    // Add a space before sequences of uppercase letters followed by lowercase letters
    // And before single uppercase letters that are not at the start of a sequence of uppercase letters
    const spacedStr = str.replace(/([A-Z])([A-Z][a-z])/g, ' $1$2')
                         .replace(/([a-z\d])([A-Z])/g, '$1 $2');
    
    // Split the string into words
    const words = spacedStr.trim().split(/\s+/);
  
    // Capitalize the first letter of each word, special handling for all-uppercase words
    const capitalizedWords = words.map((word, index) => {
      // If the word is all uppercase and not the first word, keep its case (e.g., "ID", "CID")
      if(word.toUpperCase() === word && index !== 0) {
        return word;
      }
      // Otherwise, capitalize the first letter and make the rest lowercase
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
  
    return capitalizedWords.join(' ');
  }

  render() {
    const { dataObject } = this.props;
    
    return (
        <table style={{ width: '100%', backgroundColor: 'transparent' }}>
        <tbody>
          {Object.keys(dataObject).map((key, index) => (
            <tr key={index}>
              <td style={{ width: '5%', overflowWrap: 'break-word', padding: '10px' }}>
                {/* Hidden radio button */}
                <input
                  type="radio"
                  id={`radio-${key}`} // Unique ID for each radio button
                  name="objectSelection"
                  onChange={() => this.handleRadioChange(key)}
                  checked={this.props.EASattestationNameField === key}
                  style={{ display: 'none' }} // Hide the radio button
                />
                {/* Custom square label for the radio button */}
                <label htmlFor={`radio-${key}`} className="radio-label" style={{
                  display: 'inline-block',
                  cursor: 'pointer',
                  marginRight: '10px',
                  // Apply additional styles as needed to mimic the .radio-label class
                }}></label>
              </td>
              <td style={{ width: '25%', fontWeight: 'bold', fontSize:'13px', overflowWrap: 'break-word', wordWrap: 'break-word'}}> 
                {this.formatCamelCaseString(key)}
              </td>
              <td style={{ width: '70%', fontSize: '13px', overflowWrap: 'break-word', wordBreak: 'break-all' }}>
                {dataObject[key]}
              </td>
            </tr>
          ))}
        </tbody>
      </table>      
    );
  }
}

export default EASonchainChooseName;
