import React, { Component } from 'react';
import { useState } from 'react';
import LiquiCert_Badge from './LiquiCert_Badge.png';
import LiquiCert_Footer from './LiquiCert_Footer.png';
import './App.css';
import MetaMaskButton from './components/MMbutton';
import Community from './components/community';
import ContentDetail from './components/contentDetail';
import Attestation from './components/attestation';
import {ContentPage, ExtendedContentPage} from './components/content';
import CommunitiesTable from './components/communitiesTable';
import AddCommunityInput from './components/addCommunity';
import { NavLink, BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';

function CommunityWithLocationWrapper() {
  const location = useLocation();
  
  return <Community location={location} />;
}

function ContentDetailWithLocationWrapper(props) {
  const location = useLocation();
  
  return <ContentDetail location={location} communityData={props.communityData} />;
}

function AttestationWithLocationWrapper(props) {
  const location = useLocation();
  
  return <Attestation location={location} />;
  // return <Attestation location={location} communityData={props.communityData} />;
}

class App extends Component {

  constructor(props){
    super(props);

    this.state = {
      data: null,
      addedThisSession:0,
      numCommunities: 0,
      communityData: []
    };

    // this.loadTable = this.loadTable.bind(this);
    this.addElem = this.addElem.bind(this);
    this.loadNumCommunities = this.loadNumCommunities.bind(this);
    this.loadCommunityData = this.loadCommunityData.bind(this);
    this.addAttestation = this.addAttestation.bind(this);
  }

  // Gets called just after constructor finishes
  componentDidMount() {
    // this.loadTable();
    this.loadNumCommunities();
    this.loadCommunityData();
  }

  // loadTable(){
  //   fetch("/stuff")
  //       .then((res) => res.json())
  //       .then((data) => {
  //         this.setState({
  //           data: JSON.stringify(data)
  //         })
  //         // console.log(data)
  //       });
  // }

  loadNumCommunities(){
    fetch("/numCommunities")
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            numCommunities: data.numCommunities
          })
          // console.log(data.numCommunities)
        });
  }

  loadCommunityData(){
    fetch("/communityDetails")
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            communityData: data
          })
          // console.log(data)
        });
  }

// CommunityWithLocation(props) {
//   const location = useLocation();
  
//   return <Community {...props} location={location} />;
// }

  addElem(name, email){
    fetch("/users", {
      method: "POST",
      body: JSON.stringify({
        "name": name,
        "email": email}),
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    })
      .then((res) => res.json())
      // .then(() => this.loadTable())
  }

  // addAttestation(name, payload_CID, description, trusted, signer_address, message, signature){
  addAttestation(signer_address, message, signature){
    fetch("/addAttestation", {
      method: "POST",
      body: JSON.stringify({
        // name, payload_CID, description, trusted, 
        signer_address, message, signature}),
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    })
      .then((res) => res.json())
      // .then(() => this.loadTable())
  }

  

  render () {
    return (
      <div className="App">
        <div className="navbar">
          <div className="lc-link-bold">
            <a href="/" className="lc-link-bold"> Home </a>  |
            <a href="/communities" className="lc-link-bold"> Communities </a>  |
            <a href="/content" className="lc-link-bold"> Content </a>  |
            <a href="https://docs.liquicert.io" target="_blank" className="lc-link-bold"> Docs </a>
          </div>
        </div>
        <header className="App-header">
          <center>
            {/* <Navbar /> */}
            {/* <div className='outer-plate'> */}
              <BrowserRouter>
                <Routes>
                  <Route path='/' element={<Home />}></Route>
                  <Route path='/communities' element={<Communities numCommunities={this.state.numCommunities} communityData={this.state.communityData} />}></Route>
                  <Route path="/community" element={<CommunityWithLocationWrapper />} />
                  {/* <Route path='/contact' element={<Contact />}></Route>  */}
                  {/* <Route path='/content' element={<Content />}></Route>  */}
                  <Route path='/content' element={<Content addAttestation={this.addAttestation} />} />
                  <Route path='/content2' element={<Content2 addAttestation={this.addAttestation} />} />
                  <Route path="/contentDetail" element={<ContentDetailWithLocationWrapper communityData={this.state.communityData} />} />
                  <Route path="/attestation" element={<AttestationWithLocationWrapper />} />

                </Routes>
              </BrowserRouter>
            {/* <AddUser addUserFn={this.addElem} />
            <MyTable databaseInfo={this.state.data}/> */}
            {/* </div> */}
          </center>
        </header>
      </div>
    );
  }
}

function Navbar() {
  return (
    <div className="navbar">
      <div className="lc-paragraph-bold">
        <a href="/" className="lc-paragraph-bold"> Home </a>  |
        <a href="/communities" className="lc-paragraph-bold"> Communities </a>  |
        <a href="/content" className="lc-paragraph-bold"> Content </a>
      </div>
    </div>
  );
}

const Home = () => (
  <div className='outer-plate'>
    <div className='home'>
      <br />
      <a href="/"><img src={LiquiCert_Badge} className='logo-badge' /></a>
      <img src={LiquiCert_Footer} className='mainTitle-style' />

      <p className='lc-top-paragraph-cleared'>LiquiCert lets you prove your claims to the world. </p>
      <p className='lc-middle-paragraph-cleared'>In real life, trust isn’t a simple allow list or algorithm - it flows between communities that trust each other within their areas of expertise. LiquidCert lets you document that flow of trust. It’s easy to get started by exploring communities and content below!</p>
      <p className='lc-middle-paragraph-cleared'>
      The idea of a Community in LiquiCert is based on the idea of an <a href="https://kevinjelliott.files.wordpress.com/2019/11/elliott-democracys-pin-factory-early-view.pdf">Issue Public</a>, which is a group of 
      people with interest and expertise in a given domain who deliberate within that domain and then 
      offer their conclusions to the wider public. I may not personally have time to debate the merits 
      of every argument in every field, but I can figure out which communities seem to be doing a good 
      job and delegate trust to them. This mechanism can help groups of people <a href="https://citeseerx.ist.psu.edu/document?repid=rep1&type=pdf&doi=051892b2752ef3676fb6310789115d21ef110c5e">make better decisions</a>, 
      and is thought to be a crucial aspect of information processing infrastructure in pluralistic 
      societies. 
      </p>
      <p><a href = "/communities" className='blue-script-title'>Explore Communities</a></p>
      
      <p><a href = "/content" className='blue-script-title'>Explore Content</a></p>
    </div>
  </div>
);

const Communities = ({numCommunities, communityData}) => {
  return(
    <div className='outer-plate'>
      <div className='about'>
        <a href="/"><img src={LiquiCert_Badge} className='logo-badge' /></a>
        <h1 className='blue-script-title'>Communities</h1>
        <hr class="title-line"></hr>
        <div className='lc-subtitle'>Communities tell the <br/>world who they trust</div>
        <p className='lc-top-paragraph-cleared'>Each LiquiCert community is a group of one or more people with opinions on who to trust about what. Check them out below, and make your own community if you have your own ideas about who is trustworthy!</p>
        <p className='lc-middle-paragraph-cleared'>If you have Metamask installed and you want to start a new community, enter a community name below and click register. You will be prompted to sign a transaction on Sepolia, and will be able to document delegates who you trust.</p>
        <AddCommunityInput />
        <img src="/img/SectionSeparator.svg" alt="Section Separator" class="section-divider-squiggly" />
        <CommunitiesTable numCommunities={numCommunities} communityData={communityData} />
        <img src={LiquiCert_Footer} className='footer-style' />
        </div>
      </div>
      )
};

const Content = ({ addAttestation }) => (
  <div className='contact'>
    <ContentPage addAttestation={addAttestation} />
  </div>
);

const Content2 = ({ addAttestation }) => (
  <div className='contact'>
    <ExtendedContentPage addAttestation={addAttestation} />
  </div>
);



export default App;
