import React from 'react';
import _ from 'lodash';
import EASselectTitle from './EASselectTitle'

class ImportEAS extends React.Component {
  
    constructor(props) {
        super(props);
        this.state = {
            EASUID: '0x0e60d07c85e03a4398a27e242f0dc28314f01cbd00db12e8c71c0088acde6501',
            eascontractAddress: "0xC2679fBD37d54388Ce493F1DB75320D236e1815e",
            schemaRegistryContractAddress: '0x0a7E2Ff54e76B8E6659aedc9103FB21c038050D0',
            EASretrievedSuccess: false,
            EASnetwork: 'Sepolia',
            EASretrievedAttestation: '',
            EASretrievedData: {},
            EASattestationNameField: '',
            EASsignerAddress: '',
            previewNote: '',
            successText: '',
            errorText: ''
        };

        this.retrieveEAS = this.retrieveEAS.bind(this);
        this.retrieveEAS_throttled = _.debounce(this.retrieveEAS, 300);
        this.setEASattestationName = this.setEASattestationName.bind(this)
        this.saveEASattestation = this.saveEASattestation.bind(this);
    }

    setEASattestationName(newValue) {
        this.setState({EASattestationNameField: newValue})
    }

    // This function adds an EAS attestation to the database
    async saveEASattestation() {

        // Send EAS Attestation Data
        let response = await fetch("/saveAttestation", {
            method: "POST",
            body: JSON.stringify({
                    attestationType: 'eas_onchain_v0_0_1',
                    attestationContent: {
                        network: this.state.EASnetwork.toLowerCase(),
                        EAScontractAddress: this.state.eascontractAddress,
                        schemaRegistryContractAddress: this.state.schemaRegistryContractAddress,
                        EASUID: this.state.EASUID
                    },
                    nameField: this.state.EASattestationNameField
                }),
            headers: {
            "Content-type": "application/json; charset=UTF-8"
            }
        }).then((res) => res.json())

        console.log(response)
        if(response.success){
            this.setState({
                successText: "Success! Saved EAS attestation in Liquicert.",
                errorText: ''
            })
        }else{
            this.setState({
                successText: '',
                errorText: `Error saving attestation.`
            })
            console.log(`Error saving attestation. ${response.msg}`)
        }

    }

    // This gets the ethereum attestation from the API. Use the throttled version
    async retrieveEAS() {

        // Use the getAttestation endpoint to search for the attestation with this UID 
        let response = await fetch("/getAttestation", {
            method: "POST",
            body: JSON.stringify({
                attestationType: 'eas_onchain_v0_0_1',
                attestationContent: {
                    network: this.state.EASnetwork.toLowerCase(),
                    EAScontractAddress: this.state.eascontractAddress,
                    schemaRegistryContractAddress: this.state.schemaRegistryContractAddress,
                    EASUID: this.state.EASUID
                }
                }),
            headers: {
            "Content-type": "application/json; charset=UTF-8"
            }
        }).then((res) => res.json())

        console.log(response)

        // Take actions if the call returned an attestation
        if (response.foundAttestation) {
            this.setState({
                previewNote: 'Below is a preview of the attestation. Choose a field to appear as a human-readable name. The first field will be used by default.',
                EASretrievedAttestation: response.attestation, 
                EASretrievedData:response.data,
                EASattestationNameField: Object.keys(response.data)[0],
                EASretrievedSuccess: true,
                EASsignerAddress: response.signer
            });
        } else {
            this.setState({ 
                previewNote: '',
                EASretrievedSuccess: false
            })
        }
        
    };

    updateUIDfield(term){
        this.setState({ EASUID: term});
        this.retrieveEAS_throttled();
    }

    updateEAScontractField(newUID){
        this.setState({ EASUID: newUID});
        this.retrieveEAS_throttled();
    }

    updateRegistryContractField(newUID){
        this.setState({ EASUID: newUID});
        this.retrieveEAS_throttled();
    }

    render() {

        return (
            <div>
                <p className='lc-middle-paragraph-narrow'>Import a Sepolia on-chain EAS attestation. </p>
                <div className='lc-paragraph-text' style={{ width: '60%' }}>
                    <div style={{ marginBottom: '5px' }}>
                        <label style={{ textAlign: 'left', display: 'block', marginBottom: '-20px'}}>
                        Attestation UID:
                        </label>
                        <br />
                        <div style={{ marginBottom: '25px'}}>
                            <span className="fancyx">x</span>
                            <input
                                className='sign-input-box'
                                style={{ marginLeft: 'auto', width: '400px' }}
                                value={this.state.EASUID}
                                onChange={event => { this.updateUIDfield(event.target.value) }}
                            />
                        </div>
                    </div>
                    <div style={{ marginBottom: '5px' }}>
                        <label style={{ textAlign: 'left', display: 'block', marginBottom: '-20px' }}>
                        EAS Contract Address:
                        </label>
                        <br />
                        <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <span className="fancyx">x</span>
                            <input
                                className='sign-input-box'
                                style={{ marginLeft: '2px', width: '350px' }}
                                value={this.state.eascontractAddress}
                                onChange={event => { this.updateEAScontractField(event.target.value) }}
                            />
                        </div>
                    </div>
                    <div style={{ marginBottom: '5px' }}>
                        <label style={{ textAlign: 'left', display: 'block', marginBottom: '-20px'}}>
                        Schema Registry Contract Address:
                        </label>
                        <br />
                        <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <span className="fancyx">x</span>
                            <input
                                className='sign-input-box'
                                style={{ marginLeft: '2px', width: '350px' }}
                                value={this.state.schemaRegistryContractAddress}
                                onChange={event => { this.updateRegistryContractField(event.target.value) }}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
                        <label style={{ textAlign: 'left', display: 'block' }}>
                        Network:
                        </label>
                        <div>
                        <span className="fancyx">x</span>
                        <input
                            className='sign-input-box'
                            style={{ marginLeft: 'auto', width: '100px' }}
                            value={this.state.EASnetwork}
                            readOnly
                        />
                        </div>
                    </div>
                    <p className='lc-paragraph-text' style={{textAlign: 'left'}}>{this.state.previewNote}</p>
                    <EASselectTitle 
                        EASretrievedSuccess={this.state.EASretrievedSuccess} 
                        EASretrievedData={this.state.EASretrievedData} 
                        setEASattestationName={this.setEASattestationName} 
                        EASattestationNameField={this.state.EASattestationNameField}
                        EASretrievedAttestation={this.state.EASretrievedAttestation}
                        EASnetwork={this.state.EASnetwork} />
                    {this.state.EASretrievedSuccess && (
                        <div style={{ textAlign: 'right' }}>
                            <button className='blue-script-button' onClick={this.saveEASattestation}>Import Attestation ></button>
                        </div>
                    )}
                    <p className="lc-success">{this.state.successText}</p>
                    <p className="lc-error">{this.state.errorText}</p>
                </div>
            </div>
        );
    }
}

export default ImportEAS;
