import React, { useState } from 'react';
const { ethers } = require('ethers');
const liquiCertABI = require('../LiquiCert.json').abi;
// const liquiCertAddress = '0x5E9fB969B1928ea9940719F66aC5B7c207E82aE9';
const liquiCertAddress = '0xb75087435D6B805A0a56A9c928A48dE0bA091563';

function MetaMaskButton() {
    const [errorMessage, setErrorMessage] = useState(null);

    const connectToMetaMask = async () => {
        if (typeof window.ethereum !== 'undefined') {
        try {
            await window.ethereum.request({ method: 'eth_requestAccounts' });

            const sepoliaChainId = '0xaa36a7'; // Sepolia's chain ID in hexadecimal
            const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });

            if (currentChainId !== sepoliaChainId) {
            try {
                await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: sepoliaChainId }],
                });
            } catch (switchError) {
                if (switchError.code === 4902) {
                console.error('Sepolia network is not available in MetaMask.');
                setErrorMessage('Sepolia network is not available in MetaMask. Please add it.');
                return;
                }
                console.error(switchError);
                setErrorMessage('Failed to switch to the Sepolia network.');
                return;
            }
            }

            setErrorMessage(null); // Reset error message
            alert('Connected to Sepolia network!');
        } catch (error) {
            console.error(error);
            setErrorMessage('Failed to connect to MetaMask.');
        }
        } else {
        setErrorMessage('MetaMask is not installed.');
        }
  };

  const sendTransaction = async () => {
    if (!window.ethereum) {
      setErrorMessage('MetaMask is not installed.');
      return;
    }

    try {
        console.log('outputs')
        console.log(window.ethereum)
        console.log(ethers)
        // const provider = new ethers.providers.Web3Provider(window.ethereum);
        const provider = new ethers.BrowserProvider(window.ethereum);
        console.log('outputs')
        const signer = await provider.getSigner();

        // console.log(provider)
        console.log("got them maybe")

        // Create instance of the contract, connected to the default (MM) provider
        const liquiCert = new ethers.Contract(liquiCertAddress, liquiCertABI, provider);

        console.log('outputs')

        // const txResponse = await signer.sendTransaction({
        //     to: 'RECIPIENT_ADDRESS', // Replace with the recipient's address
        //     value: ethers.utils.parseEther('0.01'),
        // });



        const delegateAddress = '0xb7B2e38eb2E82D00B053d9FFd78AC9095DF5a1FD';
        const delegateName = 'Alan';
        console.log('outputs')
        console.log(liquiCert)
        await liquiCert.connect(signer).addDelegate(delegateAddress, true, delegateName);

        // console.log('Transaction Response:', txResponse);
        alert('Transaction sent! Check MetaMask for details.');
    } catch (error) {
      console.error('Transaction Error:', error);
      setErrorMessage('An error occurred while sending the transaction.');
    }
  };

  const myContractData = async () => {
    if (!window.ethereum) {
      setErrorMessage('MetaMask is not installed.');
      return;
    }

    try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        console.log('outputs')
        const signer = await provider.getSigner();

        // console.log(provider)
        console.log("got them maybe")

        // Create instance of the contract, connected to the default (MM) provider
        const liquiCert = new ethers.Contract(liquiCertAddress, liquiCertABI, provider);

        console.log('outputs')

        // const txResponse = await signer.sendTransaction({
        //     to: 'RECIPIENT_ADDRESS', // Replace with the recipient's address
        //     value: ethers.utils.parseEther('0.01'),
        // });



        const delegateAddress = '0xb7B2e38eb2E82D00B053d9FFd78AC9095DF5a1FD';
        const delegateName = 'Alan';
        console.log('outputs')
        console.log(liquiCert)
        // await liquiCert.connect(signer).addDelegate(delegateAddress, true, delegateName);
        let numCommunities = await liquiCert.connect(signer).numberCommunities();
        return numCommunities;

        // console.log('Transaction Response:', txResponse);
        alert('Transaction sent! Check MetaMask for details.');
    } catch (error) {
      console.error('Transaction Error:', error);
      setErrorMessage('An error occurred while sending the transaction.');
    }
  };

  return (
    <div>
      <button onClick={connectToMetaMask}>Connect to MetaMask</button>
      <button onClick={sendTransaction}>Send Transaction</button>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
    </div>
  );
}

export default MetaMaskButton;
