import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

// This was the code that worked before I added the router
const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// Hybrid
root.render(
  // <BrowserRouter>
    <App />
  // </BrowserRouter>
);

// // New code from router tutorial
// ReactDOM.render((
// <BrowserRouter>
//   <App />
// </BrowserRouter>
// ), document.getElementById('root'));



// ReactDOM.render(<App />, document.querySelector('.container'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
