import React, { Component, useState } from 'react';
import LiquiCert_Badge from '../LiquiCert_Badge.png';
import LiquiCert_Footer from '../LiquiCert_Footer.png';
import {shortenAddress, CopyButton, LinkButton} from './summaryDisplayElements';
import ipfsImg from '../IPFS.png';
import LoadingPreview from '../LoadingPreview.jpg'
import Checkbox from '../Checkbox.png';
import Xbox from '../Xbox.png';
import LiquiCert_Seal_Green from '../LiquiCert_Seal_Green.png';
import LiquiCert_Seal_Red from '../LiquiCert_Seal_Red.png';
import LiquiCert_Seal_Inactive from '../LiquiCert_Seal_Inactive.png';

const DisplayTrustPath = ({pathInfo, index, communityData}) => {

    // console.log(pathInfo)
    const trustPathTitle = `Trust Path #${index + 1}:`

    //Message and styling for overall trust path
    let trustBoxImage = Xbox;
    let pathTrustValue = "Not Trusted ";
    let pathTrustStyle = 'lc-not-trusted-small-superbold';
    if (pathInfo.trusted){
        trustBoxImage = Checkbox;
        pathTrustValue = "Trusted ";
        pathTrustStyle = 'lc-trusted-small-superbold';
    }

    // Message and styling for last path element
    let attestationTrustValue = "Does Not Trust ";
    let attestationTrustStyle = 'lc-not-trusted';
    if (pathInfo.attestation.trusted){
        attestationTrustValue = "Trusts ";
        attestationTrustStyle = 'lc-trusted';
        if (!(pathInfo.trusted)){attestationTrustStyle = 'lc-trusted-inactive';}
    }

    let trustSeal = LiquiCert_Seal_Red
    if (pathInfo.trusted) {trustSeal = LiquiCert_Seal_Green}
    if (pathInfo.trusted == null) {trustSeal = LiquiCert_Seal_Inactive}

    // Track whether we've gone past a link in the trust chain that doesn't trust; affects styling later on
    let passedNoTrust = false;

    return (
        <div>
            <br />
            <p className="lc-paragraph-bold-left">{trustPathTitle}</p>
            <div style={{ width: '400px'}}>
                {pathInfo.validPath.map((item, index) => {
                    if(!(pathInfo.validPath[index].trusted)){
                        passedNoTrust = true}
                    // console.log(index)
                    // console.log(passedNoTrust)
                    if (index == 0) {
                        const returnMessage = `The ${getCommunityNameFromAddress(item.address, communityData)} community`
                        return (
                            <div style={{ textAlign: 'left'}}>
                                <span className="lc-paragraph-simple">{returnMessage}</span>
                            </div>)
                    } else {
                        let trustValue = "Does Not Trust ";
                        let trustStyle = 'lc-not-trusted';
                        if (pathInfo.validPath[index-1].trusted){
                            // console.log('Setting truthy style')
                            trustValue = "Trusts ";
                            trustStyle = 'lc-trusted';
                            if(passedNoTrust) {
                                // console.log("triggering because passed no trust")
                                    trustStyle = 'lc-trusted-inactive'}
                            // console.log(trustStyle)
                        }
                        let whoMessage = ', who'
                        return (
                            <div style={{ textAlign: 'left'}}>
                                <span className={trustStyle}>{trustValue} </span>
                                <span className={"lc-paragraph-simple"}>{getCommunityNameFromAddress(item.address, communityData)}</span>
                                <span className={"lc-paragraph-simple"}>{whoMessage} </span>
                            </div>
                        )
                    }
                })}
                <div style={{ textAlign: 'left'}}>
                    <span className={attestationTrustStyle}>{attestationTrustValue} </span>
                    <span className={"lc-paragraph-simple"}>{pathInfo.attestation.message.name}</span>
                </div>
                <div style={{display:'flex', marginTop:'10px'}}>
                    <div>
                        <img src={trustSeal} style={{width:'100px', height:'auto', paddingTop:'8px'}}></img>
                    </div>
                    <div>
                        <div style={{ textAlign: 'left', display:'flex', marginLeft:'30px', paddingTop:'10px'}}>
                            <img src={trustBoxImage} style={{width:'13px', height:'13px', marginRight:'5px'}}></img>
                            <span className={"lc-paragraph-small-superbold"} style={{whiteSpace: 'pre'}}>{"The data is "}</span>
                            <span className={pathTrustStyle} style={{whiteSpace: 'pre'}}>{pathTrustValue}</span>
                            <span className={"lc-paragraph-small-superbold"} style={{whiteSpace: 'pre'}}>{"along this path"}</span>
                        </div>
                        <div style={{ textAlign: 'left', display:'flex', marginLeft:'30px', paddingTop:'10px'}}>
                            <img src={Checkbox} style={{width:'13px', height:'13px', marginRight:'5px'}}></img>
                            <span className={"lc-paragraph-small-superbold"} style={{whiteSpace: 'pre'}}>{"Path is valid"}</span>
                        </div>
                        <div style={{ textAlign: 'left', display:'flex', marginLeft:'30px', paddingTop:'10px'}}>
                            <CopyButton textToCopy={JSON.stringify(pathInfo)} />
                            <span className={"lc-paragraph-small-superbold"} style={{whiteSpace: 'pre', marginLeft:'5px'}}>{"Copy Proof"}</span>
                        </div>
                        <div style={{ textAlign: 'left', display:'flex', marginLeft:'30px', paddingTop:'10px'}}>
                            <LinkButton proofPath={JSON.stringify(pathInfo)} />
                            <span className={"lc-paragraph-small-superbold"} style={{whiteSpace: 'pre', marginLeft:'5px'}}>{"Link to Proof"}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  };

// Try to get the name. If there isn't a community name, just return the address
function getCommunityNameFromAddress(address, communityData) {
    let toReturn = shortenAddress(address);
    communityData.forEach((item, index) =>{
        // console.log(item)
        if (item.address == address) {toReturn = item.description}
    })
    return toReturn
}

class ContentDetail extends Component {

    constructor(props){
        super(props);

        this.state = {
            address: '',
            contentInfo: [{name:'Loading data...'}],
            imgThumbnail:LoadingPreview,
            expandedRecord: [],
            trustFromProperties:{},
            trustPaths:[],
            trustPathMessage: '',
            disclaimer:'',
            communityData: []
        }

        this.contentDetails = this.contentDetails.bind(this);

    }

    componentDidMount() {
        this.contentDetails();
        // this.fetchImage();
        
    };

    loadCommunityData(){
        fetch("/communityDetails")
            .then((res) => res.json())
            .then((data) => {
              this.setState({
                communityData: data
              })
              // console.log(data)
            });
      }

    // fetchImage = () => {
    //     console.log('trying to fetch')
    //     console.log(`address:${this.state.address} is the one`)

    //     fetch(`/screenshot?url=https://${this.state.address}.ipfs.w3s.link/`)
    //       .then((response) => response.blob())
    //       .then((blob) => {
    //         const imgThumbnail = URL.createObjectURL(blob);
    //         this.setState({ imgThumbnail });
    //       })
    //       .catch((error) => console.error("Error fetching image:", error));
    //   };
      

    async contentDetails(){
        const { location } = this.props;
        // console.log(location)
        const query = new URLSearchParams(location.search);
        const address = await query.get('address');
        // console.log(`just fetched and ${address} is the address`)
        this.setState({address: address});

        fetch("/getAttestationByID?payload_CID=".concat(address))
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            contentInfo: data,
            expandedRecord: new Array(data.length).fill(0)
          })
        //   console.log(this.state.expandedRecord)
        });

        // console.log('trying to fetch')
        // console.log(`trying to fetch from :${address}`)

        // fetch(`/screenshot?url=https://${address}.ipfs.w3s.link/`)
        // fetch(`https://cid-preview-api-5880826d85b2.herokuapp.com/preview?cid=${address}`)
        fetch(`/cid-preview?cid=${address}`)
          .then((response) => {
            if (response.status === 500) {
                // Handle 500 Internal Server Error specifically
                throw new Error('Server error');
            }
            // console.log(response)
            return response.blob()})
          .then((blob) => {
            const imgThumbnail = URL.createObjectURL(blob);
            this.setState({ imgThumbnail });
          })
          .catch((error) => console.error("Error fetching image:", error));
    };

    viewDetails = (e) => {
        e.preventDefault(); // Prevent the default anchor link behavior
        console.log('Anchor tag clicked!');
        
      };

    findTrustPath = () => {

        let queryString = `/findTrustPaths?communitySrc=${this.state.trustFromProperties.address}&dataTarget=${this.state.address}`
        // console.log(queryString)

        // let response = await fetch(queryString)
        // console.log(response)


        fetch(queryString)
        .then((res) => res.json())
        .then((data) => {
            let newTrustPathMessage = 'Searching for trust paths...'
            switch(data){
                case null:
                    newTrustPathMessage = `No trust paths were found between ${this.state.trustFromProperties.description} and ${this.state.contentInfo[0].name}.`
                    break;
                default:
                    switch(data.length){
                        case 1: 
                            newTrustPathMessage = `One trust path was found between ${this.state.trustFromProperties.description} and ${this.state.contentInfo[0].name}.`
                            break;
                        case 2: newTrustPathMessage = `Two trust paths were found between ${this.state.trustFromProperties.description} and ${this.state.contentInfo[0].name}.`
                            break;
                        case 3: newTrustPathMessage = `Three trust paths were found between ${this.state.trustFromProperties.description} and ${this.state.contentInfo[0].name}.`
                            break;
                        default: newTrustPathMessage = `${data.length} trust paths were found between ${this.state.trustFromProperties.description} and ${this.state.contentInfo[0].name}.`
                            break;
                    }
                break;
            }
            this.setState({
                trustPaths: data,
                trustPathMessage: newTrustPathMessage,
                disclaimer:`Trust information is contributed by users. Before relying on it, validate the address of the ${this.state.trustFromProperties.description} community.`
            })
        });

        this.loadCommunityData()
        
      };

      setFromCommunity = (event) => {
        // console.log(event.target.value)
        this.setState({ 
            trustFromProperties: JSON.parse(event.target.value)
         });
        // console.log(event.target.value)
      }
      
      

    render(){

        // console.log(this.props.communityData)
        let attestationExplain = `Several LiquiCert contributors attested to this dataset. See what they say about it here.`;
        
        // console.log(this.state.contentInfo.length)
        switch(this.state.contentInfo.length){
            case 1:
                attestationExplain = `One LiquiCert contributor attested to this dataset. See what they say about it here.`;
                break;
            case 2:
                attestationExplain = `Two LiquiCert contributors attested to this dataset. See what they say about it here.`;
                break;
        }
        
            // console.log(this.state.contentInfo)
            return (
                <div className='outer-plate'>
                    <div className='about'>
                        <a href="/"><img src={LiquiCert_Badge} className='logo-badge' /></a>
                        <h1 className='blue-script-title'>{this.state.contentInfo[0].name}</h1>
                        <hr class="title-line"></hr>
                        <div className='lc-subtitle'>Map the flow of trust between <br/>this dataset and people you find <br />credible</div>
                        
                        <div style={{ width: '80%', clear: 'both', display: 'flex', alignItems: 'flex-start', marginTop:50 }}>
                            
                                {/* <iframe
                                    src={`https://${this.state.address}.ipfs.w3s.link/`}
                                    style={{ width: '700px', height: '180px', transform: 'scale(1)', transformOrigin: '0 0', border: '1px'}}
                                    title="Website Preview"
                                    allowTransparency="true"
                                ></iframe> */}
                            
                            <img src={this.state.imgThumbnail} width='250px' height='auto' alt="Screenshot" style={{border:"1px solid #ccc"}}/>
                            <div>
                                <p className='lc-paragraph-simple' style={{marginLeft:'20px', marginTop:'50px'}}>A preview of the dataset is shown to the left. To access the data, use this CID:</p>
                                <table style={{ margin: '0 auto', marginTop:'40px'}}><tr>
                                    <td className="lc-paragraph-bold" style={{ width: '80%'}}>{shortenAddress(this.state.address)} 
                                    <div style={{ float: 'right'}}><a href={`https://${this.state.address}.ipfs.w3s.link`} target="_blank" rel="noopener noreferrer" >
                                        <img src={ipfsImg} alt="IPFS Link" style={{ width: '15px', height: '15px', marginRight:'4px'}} /></a>
                                        <CopyButton textToCopy={this.state.address} />
                                    </div></td>
                                </tr></table>
                            </div>
                        </div>
                        <br /> <br />

                        {/* <img src="/img/SectionSeparator.svg" alt="Section Separator" class="section-divider-squiggly" />
                        <div className='lc-paragraph-text'> 
                            <h2>Attestations</h2>
                        </div>
                        <p className='lc-middle-paragraph-cleared'>{attestationExplain}</p>

                        <div style={{ width:'500px', margin: '0 auto'}}>
                            <p className='lc-paragraph-simple'>{JSON.stringify(this.state.contentInfo)}</p>
                        </div> */}
                        
                        {/* <table style={{borderCollapse: 'collapse', width: '71%'}}>
                            <tbody>
                            {this.state.contentInfo.map((item, index) => (
                                <tr key={index} style={{height: '10px'}}>
                                    {item.trusted ? (
                                    <td className='lc-trusted' style={{width:'45%'}}> Trusted</td>) : (
                                    <td className='lc-not-trusted' style={{width:'45%'}}> Not Trusted</td>)}
                                    <td className="lc-paragraph-text" style={{width:'35%', textAlign:'left'}}>{shortenAddress(item.signer_address)} <CopyButton textToCopy={this.state.address} /> </td>
                                    <td style={{ width: '15%', textAlign: 'right'}}>
                                    <a href="#" onClick={this.viewDetails(index)} className="blue-script-link-rightaligned">{'Details'}</a></td>
                            </tr>
                            ))}
                            </tbody>
                        </table> */}

                        <img src="/img/SectionSeparator.svg" alt="Section Separator" class="section-divider-squiggly" />
                        <div className='lc-paragraph-text'> 
                            <h2>Trust Flow</h2>
                        </div>
                        <p className='lc-middle-paragraph-cleared'>LiquiCert maps the flow of trust from a community to this dataset. Pick a community below to figure out whether they trust the data. If you don’t trust any of the communities listed, then make your own!</p>
                        
                        <div style={{ width: '500px', margin: '0 auto', display: 'flex', justifyContent: 'space-between'}}>
                            <div style={{ width:'50%'}}>
                                <p className='lc-paragraph-bold'> Mapping Trust From:</p>
                            </div>
                            <div style={{ width:'50%', textAlign: 'left'}}>
                                    <select style={{ width: '100%', border: 'none', fontFamily: 'lc-paragraph-simple', marginTop: '20px' }} onChange={this.setFromCommunity}>
                                        <option value='null' >Choose a Community</option>
                                        {this.props.communityData.map((item, index) =>{
                                            return <option value={JSON.stringify({description:item.description, address:item.address})} >{item.description}</option>
                                        } )}
                                    </select>
                                    <br />
                                    {this.state.trustFromProperties.address && (
                                        <div>
                                            <a href={`/community?address=${this.state.trustFromProperties.address}`} className='blue-script-link' style={{ fontSize: '14px'}}>View Community Details</a>
                                            <br />
                                            <button className="blue-script-button" style={{border: 'none', fontSize: '14px', marginLeft: '-10px'}} onClick={() => this.findTrustPath()} > {"Search >" } </button>
                                        </div>)}
                            </div>
                        </div>
                        <div style={{ width:'500px', margin: '0 auto'}}>
                            <p className='lc-paragraph-simple'>{this.state.trustPathMessage}</p>
                            {/* <p className='lc-paragraph-simple'>{JSON.stringify(this.state.trustPaths)}</p> */}
                                {this.state.trustPaths != null && (this.state.trustPaths.map((item, index) => (
                                    <DisplayTrustPath pathInfo={item} index={index} communityData={this.state.communityData} key={index} />
                                )))}
                            <br />
                            <p className='lc-paragraph-bold'>{this.state.disclaimer}</p>
                        </div>
                        <img src={LiquiCert_Footer} className='footer-style' />
                        
                    </div>
                </div>
            );
    }

};

export default ContentDetail;