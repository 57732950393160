import React, { Component, useState } from 'react';
import { useLocation, BrowserRouter, Route, Routes } from 'react-router-dom';

import LiquiCert_Badge from '../LiquiCert_Badge.png';
import LiquiCert_Footer from '../LiquiCert_Footer.png';
import {shortenAddress, CopyButton} from './summaryDisplayElements';

const { ethers } = require('ethers');
const liquiCertABI = require('../LiquiCert.json').abi;
// const liquiCertAddress = '0xec5E7D4e1EB2EdAC0Ea744E88a3CC1B44B670048';
const liquiCertAddress = '0xb75087435D6B805A0a56A9c928A48dE0bA091563';

// import React, {  } from 'react';
// import './RadioButtons.css'; // Assuming your CSS is saved in this file

const TrustRadioButtons = (props) => {
  const [selectedValue, setSelectedValue] = useState('');
  return (
    <table style={{ borderCollapse: 'collapse', width: '60%', marginTop:'10px'}}>
        <tr>
            <td style={{ width: '33%', textAlign: 'left'}} className='lc-trusted'>
                <input type="radio" id="trusted" name="trust" value='true' checked={props.newDelegateTrusted} onChange={()=>
                    {props.changeCommunityState({
                        newDelegateTrusted:true,
                        newDelegateTrustedBox:'checked',
                        newDelegateNotTrustedBox:''
                    })}
                    } hidden />
                <label htmlFor="trusted" class="radio-label"></label>Trusted
            </td>
            <td style={{ width: '33%'}} className='lc-not-trusted'>
                <input type="radio" id="notTrusted" name="trust" value='false' checked={!(props.newDelegateTrusted)} onChange={()=>
                   { props.changeCommunityState({
                        newDelegateTrusted:false,
                        newDelegateTrustedBox:'',
                        newDelegateNotTrustedBox:'checked'
                    })}
                } hidden />
                <label htmlFor="notTrusted" className="radio-label"></label> Not Trusted
            </td>
            <td className='blue-script-link-rightaligned' style={{ width: '33%'}}>
                <button className='blue-script-button'
                    onClick={() => props.addDelegate()}>{'Register >'}</button>
            </td>
        </tr>
    </table>
  );
};

class Community extends Component {

    constructor(props){
        super(props);

        this.state = {
            address: '',
            description: '',
            newDelegate: '',
            newDelegateAddress:'',
            newDelegateTrusted:true,
            successText:'',
            errorText:'',
            delegatesData: [],
            removeSuccessText:'',
            removeErrorText:''
        };

        this.communityDetails = this.communityDetails.bind(this);
        this.addDelegate = this.addDelegate.bind(this);
        // this.loadDelegatesData = this.loadDelegatesData.bind(this);
    };

    componentDidMount() {
        this.communityDetails();
        
      }

    // loadDelegatesData(){
    //     const delegatesRequest = "/delegateDetailsForCommunity?address=".concat(this.state.address)
    //     console.log(delegatesRequest);
    //     fetch(delegatesRequest)
    //         .then((res) => res.json())
    //         .then((data) => {
    //             this.setState({
    //             communityData: data
    //             })
    //             console.log(data)
    //         });
    // }

    async addDelegate(){
        // console.log(this.state)
        // console.log(this.state.newDelegate)
        // console.log(this.state.newDelegateAddress)
        // console.log(this.state.newDelegateTrusted)

        
        try {
            const provider = new ethers.BrowserProvider(window.ethereum);
            const liquiCert = new ethers.Contract(liquiCertAddress, liquiCertABI, provider);
            const signer = await provider.getSigner();
            await liquiCert.connect(signer).addDelegate(this.state.newDelegateAddress, this.state.newDelegateTrusted, this.state.newDelegate);
            this.setState({ successText: `${this.state.newDelegate} successfully created! Sepolia contract address ${liquiCertAddress}`})
            this.setState({ newDelegate: '',
                newDelegateAddress:'',
                newDelegateTrusted:true,
                errorText: ''})

        }catch (error) {
            console.error('Transaction Error:', error);
            this.setState({ successText: '', errorText: `Could not create ${this.state.newDelegate}. Does it already exist?'`})
        };
    };

    async removeDelegate(delegateToRemove){

        try {

            const provider = new ethers.BrowserProvider(window.ethereum);
            const liquiCert = new ethers.Contract(liquiCertAddress, liquiCertABI, provider);
            const signer = await provider.getSigner();
            await liquiCert.connect(signer).removeDelegate(delegateToRemove);
            this.setState({ 
                removeSuccessText: `Delegate successfully removed.`,
                removeErrorText:''
            })

        }catch (error) {
            console.error('Transaction Error:', error);
            this.setState({ removeSuccessText: '', removeErrorText: `Could not remove delegate. Are you signed in as the administrator?'`})
        };
    };

    changeCommunityState = (newState) => {
        this.setState(newState);
      };
      

    async communityDetails(){
        const { location } = this.props;
        const query = new URLSearchParams(location.search);
        const address = query.get('address');
        await this.setState({address});

        fetch("/communityDetailsByAddress?address=".concat(address))
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            description: data.description
          })
          // console.log(data)
        });

        const delegatesRequest = "/delegateDetailsForCommunity?address=".concat(this.state.address)
        console.log(delegatesRequest);
        fetch(delegatesRequest)
            .then((res) => res.json())
            .then((data) => {
                this.setState({
                    delegatesData: data
                })
                console.log(data)
            });
    };

    render() {

        const firstParagraphString = `This is the page for ${this.state.description}, an individual LiquiCert community. A community in LiquiCert is an entity on the blockchain that has opinions about who to trust and who not to trust. `
        const communityDelegationParagraphString = `The ${this.state.description} Community delegates trust to the following list of contributors. Delegates marked as “Not Trusted” are known to the community as purveyors of misinformation.`
      return (
        <div className='outer-plate'>
            <div className='about'>
                <a href="/"><img src={LiquiCert_Badge} className='logo-badge' /></a>
                <h1 className='blue-script-title'>{this.state.description}</h1>
                <hr class="title-line"></hr>
                <div className='lc-subtitle'>Is a LiquiCert Community</div>
                <p className='lc-top-paragraph-cleared'>{firstParagraphString}</p>
                <p className='lc-middle-paragraph-cleared'>Details on this community:</p>
                <div className='lc-paragraph-text'>
                    <table style={{ borderCollapse: 'collapse', width: '50%'}}>
                        <tr>
                            <td className='lc-paragraph-bold' style={{ width: '50%', textAlign: 'left'}}>
                                    {shortenAddress(this.state.address)}
                                    <CopyButton textToCopy={this.state.address} />
                            </td>
                        <td style={{ width: '50%', textAlign: 'right'}}>
                                <a href={"https://sepolia.etherscan.io/address/".concat(this.state.address)} target="_blank" className="blue-script-link-rightaligned">{'View on Sepolia >'}</a>
                            </td>
                        </tr>
                    </table>
                </div>

                <br></br>
                <img src="/img/SectionSeparator.svg" alt="Section Separator" class="section-divider-squiggly" />
                <div className='lc-paragraph-text'> 
                    <h2>Trust Delegation</h2>
                    <p className='lc-middle-paragraph-cleared'>{communityDelegationParagraphString}</p>


                    <table style={{ borderCollapse: 'collapse', width: '71%' }}>
                    {/* <table style={{ border: "1px solid black", width: '80%' }}> */}
                    <tbody>
                    {this.state.delegatesData.map((item, index) => (
                        <tr key={index}>
                            {/* <td className="lc-paragraph-bold" style={{ width: '5%' }}>x</td> */}
                            <td className='lc-button-like-text' style={{ width: '5%'}}>
                                <button className='lc-button-like-text'
                                    onClick={() => this.removeDelegate(item.delegateID)}>x</button>
                            </td>
                            <td className="lc-paragraph-bold" style={{ width: '40%' }}>{item.delegateName}</td>
                            <td className="lc-paragraph-text" style={{ width: '35%'  }}>{shortenAddress(item.delegateID)} <CopyButton textToCopy={item.delegateID} /></td>
                            <td className={item.trusted ? 'lc-trusted' : 'lc-not-trusted'} style={{ width: '20%', textAlign: 'right'}}>
                                {item.trusted ? 'Trusted' : 'Not Trusted'}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <p className="lc-success">{this.state.removeSuccessText}</p>
                <p className="lc-error">{this.state.removeErrorText}</p>
                {
                    this.state.delegatesData.length == 0 ? (
                        <p className='lc-middle-paragraph-cleared'>This community hasn't assigned any delegates yet.</p>
                    ) : null
                    }
                {
                    this.state.delegatesData.length > 0 ? (
                        <p className='lc-middle-paragraph-cleared'>If you are signed in as the administrator for this community, click the <b>x</b> next to a delegate's name to remove them.</p>
                    ) : null
                    }

                

                </div>

                <br></br>
                <img src="/img/SectionSeparator.svg" alt="Section Separator" class="section-divider-squiggly" />

                <div className='lc-paragraph-text'> 
                    <h2>Add Delegates</h2>
                    <p className='lc-middle-paragraph-cleared'>If you are signed in as the community administrator, you can add trust delegates to this community.</p>
                    <table style={{ borderCollapse: 'collapse', width: '60%'}}>
                        <tr>
                            <td>
                                New Delegate Name: 
                            </td>
                            <td>
                                <span className = "fancyx">x</span>
                                <input 
                                    className='sign-input-box'
                                    value = {this.state.newDelegate}
                                    onChange={event => {
                                        this.setState({ newDelegate: event.target.value})
                                    } }
                                    onKeyDown={this.handleKeyDown}
                                    />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                New Delegate Address: 
                            </td>
                            <td>
                                <span className = "fancyx">x</span>
                                <input 
                                    className='sign-input-box'
                                    value = {this.state.newDelegateAddress}
                                    onChange={event => {
                                        this.setState({ newDelegateAddress: event.target.value})
                                    } }
                                    onKeyDown={this.handleKeyDown}
                                    />
                            </td>
                        </tr>
                    </table>
                    <TrustRadioButtons addDelegate={this.addDelegate} 
                        changeCommunityState ={this.changeCommunityState} 
                        newDelegateTrusted={this.state.newDelegateTrusted} />

                    <p className="lc-success">{this.state.successText}</p>
                    <p className="lc-error">{this.state.errorText}</p>
                </div>
                
                <img src={LiquiCert_Footer} className='footer-style' />
            </div>
        </div>
      );
    }
  }

  export default Community;