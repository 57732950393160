import React, { Component, useState } from 'react';
import LiquiCert_Badge from '../LiquiCert_Badge.png';
import LiquiCert_Footer from '../LiquiCert_Footer.png';
import {shortenAddress, CopyButton} from './summaryDisplayElements';
import ipfsImg from '../IPFS.png';
import AttestationPreview from './attestationPreview'
import SignCID from './SignCID'
import ImportEAS from './ImportEAS'

const { ethers } = require('ethers');


class ContentPage extends Component {

    constructor(props){
        super(props);

        this.state = {
            address: '',
            newDatasetName:'',
            newDatasetCID:'',
            newDatasetDescription:'',
            newDataTrusted:true,
            allContent:[],
            previewData:"",
            attestationModeSelected : 'sign',
            searchTerm: ''
        };
        this.fetchAllContent = this.fetchAllContent.bind(this);
        this.searchContent = this.searchContent.bind(this);
    };

    componentDidMount() {
        this.fetchAllContent();
        
    }

    async fetchAllContent(){
        fetch("/allAttestations")
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            allContent: data
          })
          console.log(data)
        });
    }

    async searchContent(searchTerm){
        let response = await fetch("/searchAttestations", {
            method: "POST",
            body: JSON.stringify({searchTerm}),
            headers: {
            "Content-type": "application/json; charset=UTF-8"
            }
        }).then((res) => res.json())
        return response
    }

    setPreviewMessage(itemInfo){
        // console.log(itemInfo)
        this.setState({previewData: itemInfo});
    }

    setAttestationMode = (mode) => {
        this.setState({ attestationModeSelected: mode });
      }


    render() {
        return(
            <div className='outer-plate'>
                <div className='about'>
                    {/* <p>{this.state.previewMessage}</p> */}
                    <AttestationPreview previewData={this.state.previewData} searchContent={this.searchContent} />
                    <a href="/"><img src={LiquiCert_Badge} className='logo-badge' /></a>
                    <h1 className='blue-script-title'>Content</h1>
                    <hr className="title-line"></hr>
                    <div className='lc-subtitle'>Explore content to see if <br/>you trust it</div>
                    <p className='lc-top-paragraph-cleared'>Here is content that has been tagged using LiquiCert. View content details to see whether you or your community trusts it.</p>
                    <div className='lc-paragraph-text' style={{ width: '70%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
                        <label style={{ textAlign: 'left' }}>
                        Search for Attestations:
                        </label>
                        <div>
                        <span className="fancyx">x</span>
                        <input
                            className='sign-input-box'
                            style={{ marginLeft: 'auto', width: '250px' }}
                            value={this.state.searchTerm}
                            onChange={event => { this.setState({ searchTerm: event.target.value }) }}
                        />
                        </div>
                    </div>
                    <table style={{ borderCollapse: 'collapse', width: '71%' }}>
                        {/* <table style={{ border: "1px solid black", width: '80%' }}> */}
                        <tbody>
                        {this.state.allContent.map((item, index) => (
                            <tr key={index} style={{height: '10px'}}>
                                <td className="lc-paragraph-bold" style={{ width: '45%'}} 
                                    onMouseEnter={() => this.setPreviewMessage(item)} 
                                    onMouseLeave={() => this.setPreviewMessage('')}>{item.name}</td>
                                <td className="lc-paragraph-text" style={{ width: '35%'}}>{shortenAddress(item.payload_cid)} 
                                <div style={{ float: 'right'}}><a href={`https://${item.payload_cid}.ipfs.w3s.link`} target="_blank" rel="noopener noreferrer" >
                                    <img src={ipfsImg} alt="IPFS Link" style={{ width: '15px', height: '15px', marginRight:'4px'}} /></a>
                                    <CopyButton textToCopy={item.payload_cid} />
                                </div>
                                
                                </td>
                                <td style={{ width: '20%', textAlign: 'right'}}>
                                    <a href={"contentDetail?address=".concat(item.payload_cid)} className="blue-script-link-rightaligned">{'Details >'}</a></td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    
                    
                    <br />
                    <img src="/img/SectionSeparator.svg" alt="Section Separator" className="section-divider-squiggly" />
                    
                    <div className='lc-paragraph-text'> 
                        <h2>Attestations</h2>
                        <p className='lc-middle-paragraph-cleared'>Create a record here about some data, or import an attestation from another service.</p>
                        <div className="lc-link-bold">
                            <span onClick={() => this.setAttestationMode('sign')} className={this.state.attestationModeSelected === 'sign' ? 'lc-link-selected' : 'lc-link-bold'} style={{ cursor: 'pointer' }}>Sign CID</span>
                            <span style={{paddingLeft: "5px", paddingRight: "5px"}}>|</span>
                            <span onClick={() => this.setAttestationMode('eas')} className={this.state.attestationModeSelected === 'eas' ? 'lc-link-selected' : 'lc-link-bold'} style={{ cursor: 'pointer' }}>Ethereum Attestation</span>
                        </div>

                        {this.state.attestationModeSelected === 'sign' ? (
                            <SignCID fetchAllContent={this.fetchAllContent} addAttestation={this.props.addAttestation} />
                            ) : this.state.attestationModeSelected === 'eas' ? (
                            <ImportEAS />
                            ) : (
                            <div>Select a mode</div>
                            )}

                    </div>

                    <img src={LiquiCert_Footer} className='footer-style' />
                </div>
            </div>
            );
    };
};

class ExtendedContentPage extends Component {

    constructor(props){
        super(props);

        this.state = {
            address: '',
            newDatasetName:'',
            newDatasetCID:'',
            newDatasetDescription:'',
            newDataTrusted:true,
            allContent:[],
            v2content:[],
            previewData:"",
            attestationModeSelected : 'sign',
            searchTerm: ''
        };
        this.fetchAllContent = this.fetchAllContent.bind(this);
        this.searchContent = this.searchContent.bind(this);
    };

    componentDidMount() {
        this.fetchAllContent();
        this.searchContent(this.state.searchTerm);
    }

    async fetchAllContent(){
        fetch("/allAttestations")
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            allContent: data
          })
        //   console.log(data)
        });
    }

    async searchContent(searchTerm) {
        console.log(JSON.stringify(searchTerm));
        try {
            let response = await fetch("/searchAttestations", {
                method: "POST",
                body: JSON.stringify({searchTerm}),
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            });
            let data = await response.json();
            this.setState({ v2content: data });
            console.log(data);
            return response;
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    }

    setPreviewMessage(itemInfo){
        // console.log(itemInfo)
        this.setState({previewData: itemInfo});
    }

    setAttestationMode = (mode) => {
        this.setState({ attestationModeSelected: mode });
      }


    render() {
        return(
            <div className='outer-plate'>
                <div className='about'>
                    {/* <p>{this.state.previewMessage}</p> */}
                    {/* <AttestationPreview previewData={this.state.previewData} searchContent={this.searchContent} /> */}
                    <a href="/"><img src={LiquiCert_Badge} className='logo-badge' /></a>
                    <h1 className='blue-script-title'>Content V2</h1>
                    <hr className="title-line"></hr>
                    <div className='lc-subtitle'>Explore content to see if <br/>you trust it</div>
                    <p className='lc-top-paragraph-cleared'>Here is content that has been tagged using LiquiCert. View content details to see whether you or your community trusts it.</p>
                    <div className='lc-paragraph-text' style={{ width: '70%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
                        <label style={{ textAlign: 'left' }}>
                        Search for Attestations:
                        </label>
                        <div>
                        <span className="fancyx">x</span>
                        <input
                            className='sign-input-box'
                            style={{ marginLeft: 'auto', width: '250px' }}
                            value={this.state.searchTerm}
                            onChange={event => { this.setState({ searchTerm: event.target.value }) }}
                        />
                        </div>
                    </div>
                    <table style={{ borderCollapse: 'collapse', width: '71%' }}>
                        {/* <table style={{ border: "1px solid black", width: '80%' }}> */}
                        <tbody>
                        {this.state.allContent.map((item, index) => (
                            <tr key={index} style={{height: '10px'}}>
                                <td className="lc-paragraph-bold" style={{ width: '45%'}} 
                                    onMouseEnter={() => this.setPreviewMessage(item)} 
                                    onMouseLeave={() => this.setPreviewMessage('')}>{item.name}</td>
                                <td className="lc-paragraph-text" style={{ width: '35%'}}>{shortenAddress(item.payload_cid)} 
                                <div style={{ float: 'right'}}><a href={`https://${item.payload_cid}.ipfs.w3s.link`} target="_blank" rel="noopener noreferrer" >
                                    <img src={ipfsImg} alt="IPFS Link" style={{ width: '15px', height: '15px', marginRight:'4px'}} /></a>
                                    <CopyButton textToCopy={item.payload_cid} />
                                </div>
                                
                                </td>
                                <td style={{ width: '20%', textAlign: 'right'}}>
                                    <a href={"contentDetail?address=".concat(item.payload_cid)} className="blue-script-link-rightaligned">{'Details >'}</a></td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    
                    
                    <br />
                    <img src="/img/SectionSeparator.svg" alt="Section Separator" className="section-divider-squiggly" />
                    
                    <div className='lc-paragraph-text'> 
                        <h2>Attestations</h2>
                        <p className='lc-middle-paragraph-cleared'>Create a record here about some data, or import an attestation from another service.</p>
                        <div className="lc-link-bold">
                            <span onClick={() => this.setAttestationMode('sign')} className={this.state.attestationModeSelected === 'sign' ? 'lc-link-selected' : 'lc-link-bold'} style={{ cursor: 'pointer' }}>Sign CID</span>
                            <span style={{paddingLeft: "5px", paddingRight: "5px"}}>|</span>
                            <span onClick={() => this.setAttestationMode('eas')} className={this.state.attestationModeSelected === 'eas' ? 'lc-link-selected' : 'lc-link-bold'} style={{ cursor: 'pointer' }}>Ethereum Attestation</span>
                        </div>

                        {this.state.attestationModeSelected === 'sign' ? (
                            <SignCID fetchAllContent={this.fetchAllContent} addAttestation={this.props.addAttestation} />
                            ) : this.state.attestationModeSelected === 'eas' ? (
                            <ImportEAS />
                            ) : (
                            <div>Select a mode</div>
                            )}

                    </div>

                    <img src={LiquiCert_Footer} className='footer-style' />
                </div>
            </div>
            );
    };
};


export {ContentPage, ExtendedContentPage};