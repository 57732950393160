import React from 'react';
import EASonchainChooseName from './EASonchainChooseName'
import eas_logo from '../eas_logo.png'

class EASselectTitle extends React.Component{

    constructor(props){
        super(props);
    }

    render() {

        // Find title based on the selected index
        const objKeys = Object.keys(this.props.EASretrievedData);
        let titleText;
        if (objKeys.length >0){
            // titleText = this.props.EASretrievedData[objKeys[this.props.EASattestationNameIndex]];
            titleText = this.props.EASretrievedData[this.props.EASattestationNameField];
            if (!(typeof(titleText)=='string')){titleText = JSON.stringify(titleText);}
        }
        

        if (!this.props.EASretrievedSuccess){
            return(<div></div>)
        }else{

            // Construct EAS link only if we have all the params
            let EASscanLink='';
            let retrievedAttestationParsed;
            let network = this.props.EASnetwork;
            if (typeof(this.props.EASretrievedAttestation)=='string'){
                retrievedAttestationParsed = JSON.parse(this.props.EASretrievedAttestation)
                if (typeof(network)=='string'){
                    if (network.toLowerCase() == 'sepolia'){
                        EASscanLink = `https://sepolia.easscan.org/attestation/view/${retrievedAttestationParsed[0]}`;
                    }
                }
            }

            return (
                <div className='EAS-nameSelect-plate'>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '20px' }}>
                        <a href={EASscanLink} target="_blank" rel="noopener noreferrer">
                            <img src={eas_logo} alt="EAS Logo" style={{ maxWidth: '15px', maxHeight: 'auto' }} />
                        </a>
                    </div>
                    <p className='preview-bold'>{titleText}</p>
                    <EASonchainChooseName 
                        dataObject={this.props.EASretrievedData}
                        setEASattestationName={this.props.setEASattestationName}
                        EASattestationNameField={this.props.EASattestationNameField} />
                </div>
            )
        }
    }
}

export default EASselectTitle;