import React, { Component, useState } from 'react';
const { ethers } = require('ethers');
const liquiCertABI = require('../LiquiCert.json').abi;
// const liquiCertAddress = '0xec5E7D4e1EB2EdAC0Ea744E88a3CC1B44B670048';
const liquiCertAddress = '0xb75087435D6B805A0a56A9c928A48dE0bA091563';


class AddCommunityInput extends Component {
    constructor(props){
        super(props);

        this.state = {
            term: '',
            errorText: '',
            successText: ''
        };
        this.addCommunity = this.addCommunity.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    };

    async addCommunity(){
        console.log(this.state.term)
        try {
            const provider = new ethers.BrowserProvider(window.ethereum);
            const liquiCert = new ethers.Contract(liquiCertAddress, liquiCertABI, provider);
            const signer = await provider.getSigner();
            await liquiCert.connect(signer).createCommunity(this.state.term);
            this.setState({ successText: `${this.state.term} successfully created! Sepolia contract address ${liquiCertAddress}`})
            this.setState({ term: '', errorText: ''})

        }catch (error) {
            console.error('Transaction Error:', error);
            this.setState({ successText: '', errorText: `Could not create ${this.state.term}.<br>Has this wallet already created a community?'`})
        };
    };

    handleKeyDown(event) {
        // Check if the key pressed is 'Enter'
        if (event.key === 'Enter') {
          console.log('Enter key was pressed.');
          this.addCommunity();
        }
      };

    render () {

        const errorParagraphs = this.state.errorText.split('<br>').map((line, index) => (
            <p key={index} className="lc-error">{line}</p>
        ));
        
        return(
                <div className='lc-paragraph-text'> 
                    New Community Name: &nbsp; 
                    <span className = "fancyx">x</span>
                    <input 
                        className='sign-input-box'
                        value = {this.state.term}
                        onChange={event => {
                            this.setState({ term: event.target.value})
                        } }
                        onKeyDown={this.handleKeyDown}
                        />
                    &nbsp;
                    <button className='blue-script-button'
                    onClick={() => this.addCommunity()}>Register</button>
                    <p className="lc-success">{this.state.successText}</p>
                    {errorParagraphs}
                </div>
            
        );
    };

    

};

export default AddCommunityInput;