import React, { useState, useEffect } from 'react';

const AttestationPreview = ({ previewData }) => {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  const updateCursorPosition = (e) => {
    setCursorPosition({ x: e.clientX, y: e.clientY });
  };

  useEffect(() => {
    window.addEventListener('mousemove', updateCursorPosition);

    return () => {
      window.removeEventListener('mousemove', updateCursorPosition);
    };
  }, []);

  if (!previewData) {
    return null;
  }

  return (

    <div
      style={{
        position: 'fixed',
        left: `${cursorPosition.x + 10}px`, // Offset from cursor
        top: `${cursorPosition.y + 10}px`, // Offset from cursor
        backgroundColor: 'white',
        border: '1px solid #DEDDDD',
        borderRadius: '5px',
        padding: '10px',
        zIndex: 1000,
        width: '300px'
      }}
    >
      
      <table style={{width: '90%', paddingTop: '10px'}}>
        <tbody>
            <tr style={{height: '10px'}}>
                {/* style={{border:'1px solid #ddd'}} */}
                <td style={{width: '30%'}} className='preview-bold' >Name:</td>
                <td className='preview-text'>{previewData.name}</td>
            </tr>
            <tr style={{height: '10px'}}>
                <td style={{width: '30%'}} className='preview-bold'>Description:</td>
                <td className='preview-text'>{previewData.description}</td>
            </tr>
            <tr style={{height: '10px'}}>
                <td style={{width: '30%'}} className='preview-bold'>Attestation Type:</td>
                <td className='preview-text'>{"Ethereum wallet signature"}</td>
            </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AttestationPreview;
