import React, { Component, useState } from 'react';
import LiquiCert_Badge from '../LiquiCert_Badge.png';
import LiquiCert_Footer from '../LiquiCert_Footer.png';
import {shortenAddress, CopyButton, LinkButton, CopySprite} from './summaryDisplayElements';
import ipfsImg from '../IPFS.png';
import LoadingPreview from '../LoadingPreview.jpg'
import Checkbox from '../Checkbox.png';
import Xbox from '../Xbox.png';
import LiquiCert_Seal_Green from '../LiquiCert_Seal_Green.png';
import LiquiCert_Seal_Red from '../LiquiCert_Seal_Red.png';
import LiquiCert_Seal_Inactive from '../LiquiCert_Seal_Inactive.png';
import Cert_Flourish from '../Cert_Flourish.jpg';

import subway_start_g from '../subway/subway_start_g.jpg';
import subway_start_r from '../subway/subway_start_r.jpg';
import subway_start_b from '../subway/subway_start_b.jpg';
import subway_middle_g_g from '../subway/subway_middle_g_g.jpg';
import subway_middle_r_g from '../subway/subway_middle_r_g.jpg'; // ie signal comes in red, this link is green
import subway_middle_b_g from '../subway/subway_middle_b_g.jpg';
import subway_middle_g_r from '../subway/subway_middle_g_r.jpg';
import subway_middle_r_r from '../subway/subway_middle_r_r.jpg';
import subway_middle_b_r from '../subway/subway_middle_b_r.jpg';
import subway_end_g_g from '../subway/subway_end_g_g.jpg';
import subway_end_r_g from '../subway/subway_end_r_g.jpg';
import subway_end_b_g from '../subway/subway_end_b_g.jpg';
import subway_end_g_r from '../subway/subway_end_g_r.jpg';
import subway_end_r_r from '../subway/subway_end_r_r.jpg';
import subway_end_b_r from '../subway/subway_end_b_r.jpg';


// Try to get the name. If there isn't a community name, just return the address
function getCommunityNameFromAddress(address, communityData) {
    let toReturn = shortenAddress(address);
    communityData.forEach((item, index) =>{
        // console.log(item)
        if (item.address == address) {toReturn = item.description}
    })
    return toReturn
}

const CertificationPathCard = ({pathInfo, assetName, communityData, pathCID}) => {

    // console.log(pathInfo)
    const trustPathTitle = `${assetName} Trust Path:`


    // Message and styling for overall trust path
    let trustBoxImage = Xbox;
    let pathTrustValue = "Not Trusted ";
    let pathTrustStyle = 'path-certificate-not-trusted-small-superbold';
    if (pathInfo.trusted){
        trustBoxImage = Checkbox;
        pathTrustValue = "Trusted ";
        pathTrustStyle = 'path-certificate-trusted-small-superbold';
    }

    // Message and styling for last path element
    let attestationTrustValue = "Does Not Trust ";
    let attestationTrustStyle = 'path-certificate-not-trusted';
    if (pathInfo.attestation.trusted){
        attestationTrustValue = "Trusts ";
        attestationTrustStyle = 'path-certificate-trusted';
        if (!(pathInfo.trusted)){attestationTrustStyle = 'path-certificate-trusted-inactive';}
    }

    let trustSeal = LiquiCert_Seal_Red
    if (pathInfo.trusted) {trustSeal = LiquiCert_Seal_Green}
    if (pathInfo.trusted == null) {trustSeal = LiquiCert_Seal_Inactive}

    // Track whether we've gone past a link in the trust chain that doesn't trust; affects styling later on
    let passedNoTrust = false;
    
    
    // Figure out what the final subway image is
    let lastImage = subway_end_g_g;
    if (pathInfo.trusted == false) {
        if (pathInfo.attestation.trusted == true) {
            lastImage = subway_end_r_g;
        }
        if (pathInfo.attestation.trusted == false) {
            lastImage = subway_end_g_r;
        }
    }
    if (pathInfo.trusted == null) {
        // Not enough information to tell
        lastImage = subway_end_b_g;
    }

    // As we loop through the path, track whether or not the current link is trusted
    let currentTrust = pathInfo.validPath[0].trusted; 

    return (
        <div className="certification-path-linked">
            <div>
                
                <img src={Cert_Flourish} style={{width:'70px', height:'auto'}}></img>
                <p className="path-certificate-title">{trustPathTitle}</p>
                <div style={{ width: '350px'}}>
                    <div style ={{width: '350px'}}>
                                {
                                    pathInfo.validPath.map((item, index) => {
                                        console.log(`At ${index}: ${currentTrust}`)
                                        console.log(`  > This item: ${item.trusted}`)
                                        if(!(pathInfo.validPath[index].trusted)){
                                            passedNoTrust = true}
                                        // console.log(index)
                                        // console.log(passedNoTrust)
                                        if (index == 0) {
                                            const returnMessage = `The ${getCommunityNameFromAddress(item.address, communityData)} community`
                                            let subwayImg = subway_start_g;
                                            if (item.trusted == false) {subwayImg = subway_start_r}; // Currently this would never happen
                                            if (item.trusted == null) {subwayImg = subway_start_b};
                                            return (
                                                <div style={{ textAlign: 'left', display:'flex', marginLeft:'10px', paddingTop:'10px'}}>
                                                    <img src={subwayImg} style={{width:'12px', height:'auto', marginRight:'15px'}}></img>
                                                    <span className="path-certificate-plaintext">{returnMessage}</span>
                                                </div>)
                                        } else {
                                            let trustValue = "Does Not Trust ";
                                            let trustStyle = 'path-certificate-not-trusted';
                                            if (pathInfo.validPath[index-1].trusted){
                                                // console.log('Setting truthy style')
                                                trustValue = "Trusts ";
                                                trustStyle = 'path-certificate-trusted';
                                                if(passedNoTrust) {
                                                    // console.log("triggering because passed no trust")
                                                        trustStyle = 'path-certificate-trusted-inactive'}
                                                // console.log(trustStyle)
                                            }
                                            let whoMessage = ', who'
                                            let nextImage = subway_middle_g_g;
                                            if (currentTrust == true) {
                                                // If this link is trusted do nothing
                                                if (item.trusted == false) {
                                                    nextImage = subway_middle_g_r;
                                                    currentTrust = false;
                                                }
                                            }
                                            if (currentTrust == false) {
                                                if (item.trusted == true) {
                                                    nextImage = subway_middle_r_g;
                                                    // Nothing to current trust
                                                }
                                                if (item.trusted == false) {
                                                    nextImage = subway_middle_r_r;
                                                    currentTrust = null;
                                                }
                                            }
                                            if (currentTrust == null) {
                                                if (item.trusted == true) {
                                                    nextImage = subway_middle_b_g;
                                                }
                                                if (item.trusted == false) {
                                                    nextImage = subway_middle_b_r;
                                                }
                                            }
                                            return (
                                                <div style={{ textAlign: 'left', display:'flex', marginLeft:'10px', alignItems: 'center'}}>
                                                    <img src={nextImage} style={{width:'12px', height:'auto', marginRight:'15px'}}></img>
                                                    <span className={trustStyle} style={{ marginRight: '5px', paddingTop:'3px'}}>{trustValue} </span>
                                                    <span className={"path-certificate-plaintext"} style={{paddingTop:'3px'}}>{getCommunityNameFromAddress(item.address, communityData)}</span>
                                                    <span className={"path-certificate-plaintext"} style={{paddingTop:'3px'}}>{whoMessage} </span>
                                                </div>
                                            )
                                        }
                                })}
                        {/* Final trust line, covering this asset */}
                        <div style={{ textAlign: 'left', display:'flex', marginLeft:'10px', alignItems: 'center'}}>
                            {/* Have to use conditional logic because the last image depends on the currentTrust state immediately before */}
                            {
                                pathInfo.trusted === null && currentTrust === false ? (
                                    <img src={subway_end_r_r} style={{width: '12px', height: 'auto', marginRight: '15px'}} />
                                ) : (
                                    <img src={lastImage} style={{width: '12px', height: 'auto', marginRight: '15px'}} />
                                )
                            }
                            <span className={attestationTrustStyle} style={{ marginRight: '5px', paddingTop:'4px'}}>{attestationTrustValue} </span>
                            <span className={"path-certificate-plaintext"} style={{paddingTop:'4px'}}>{assetName}</span>
                        </div>
                    </div>
                    <div style={{display:'flex', marginTop:'10px'}}>
                        <div>
                            <img src={trustSeal} style={{width:'90px', height:'auto', paddingTop:'8px'}}></img>
                        </div>
                        <div>
                            <div style={{ textAlign: 'left', display:'flex', marginLeft:'30px', paddingTop:'10px'}}>
                                <img src={trustBoxImage} style={{width:'13px', height:'13px', marginRight:'5px'}}></img>
                                <span className={"path-certificate-paragraph-small-superbold"} style={{whiteSpace: 'pre'}}>{"The data is "}</span>
                                <span className={pathTrustStyle} style={{whiteSpace: 'pre'}}>{pathTrustValue}</span>
                                <span className={"path-certificate-paragraph-small-superbold"} style={{whiteSpace: 'pre'}}>{"along this path"}</span>
                            </div>
                            <div style={{ textAlign: 'left', display:'flex', marginLeft:'30px', paddingTop:'10px'}}>
                                <img src={Checkbox} style={{width:'13px', height:'13px', marginRight:'5px'}}></img>
                                <span className={"path-certificate-paragraph-small-superbold"} style={{whiteSpace: 'pre'}}>{"Path is valid"}</span>
                            </div>
                            <div style={{ textAlign: 'left', display:'flex', marginLeft:'30px', paddingTop:'10px'}}>
                                <CopyButton textToCopy={JSON.stringify(pathInfo)} />
                                <span className={"path-certificate-paragraph-small-superbold"} style={{whiteSpace: 'pre', marginLeft:'5px'}}>{"Copy Proof"}</span>
                            </div>
                            <div style={{ textAlign: 'left', display:'flex', marginLeft:'30px', paddingTop:'10px'}}>
                                <LinkButton proofPath={JSON.stringify(pathInfo)} />
                                <span className={"path-certificate-paragraph-small-superbold"} style={{whiteSpace: 'pre', marginLeft:'5px'}}>{"Link to Proof"}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <CopySprite textToCopy={`<iframe src="https://liquicert.io/badge/embed?path_CID=${pathCID}" style={{ border: 'none' }} title="Liquicert Badge" allowTransparency="true"></iframe>`} />
                </div>
            </div>
        </div>
    );
  };


export default CertificationPathCard;