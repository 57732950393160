import React from 'react';
import ipfsImg from '../IPFS.png';
const { ethers } = require('ethers');

class SignCID extends React.Component {
//   state = {
//     newDatasetName: '',
//     newDatasetCID: '',
//     newDatasetDescription: '',
//     newDataTrusted: true
//   };

    constructor(props) {
        super(props);
        this.state = {
        newDatasetName: '',
        newDatasetCID: '',
        newDatasetDescription: '',
        newDataTrusted: true,
        successText: '',
        errorText: ''
        };
    
        this.createAttestation = this.createAttestation.bind(this);
        this.verifySignature = this.verifySignature.bind(this);
  }  

    async createAttestation(){
        console.log('adding')

        const attestation = {
            name: this.state.newDatasetName,
            CID: this.state.newDatasetCID,
            description:this.state.newDatasetDescription,
            trusted: this.state.newDataTrusted
        }
        console.log(attestation);
        const attestation_message = JSON.stringify(attestation);

        try {
            const provider = new ethers.BrowserProvider(window.ethereum);
            const signer = await provider.getSigner();
            const signature = await signer.signMessage(attestation_message);
            console.log(signature)
            

            const signerAddress = await signer.getAddress();
            this.verifySignature(attestation_message, signature, signerAddress);
            // addAttestation(name, payload_CID, description, trusted, signer_address, message, signature)
            await this.props.addAttestation(
                // this.state.newDatasetName, 
                // this.state.newDatasetCID, 
                // this.state.newDatasetDescription, 
                // this.state.newDataTrusted, 
                signerAddress, 
                attestation_message, 
                signature)
            this.props.fetchAllContent();
            this.setState({ successText: `Successfully attested!`})
            this.setState({ newDatasetName:'',
                newDatasetCID:'',
                newDatasetDescription:'',
                newDataTrusted:true, 
                errorText: ''})

        }catch (error) {
            console.error('Transaction Error:', error);
            this.setState({ successText: '', errorText: `Could not create attestation.`})
        };
    }

    async verifySignature(message, signature, expectedAddress){

        let signerAddressCalculated = await (ethers.verifyMessage(message, signature));//For some reason this doesn't resolve...

        console.log(expectedAddress)
        console.log(signerAddressCalculated)
        console.log(message)
        
        if(signerAddressCalculated == expectedAddress){
            console.log('Verified!')
            return true
        }else{
            console.log('Signature or address incorrect')
            return false
        }
    }

  render() {
    return (
      <div>
        <p className='lc-middle-paragraph-narrow'>Sign data with a content identifier (CID) that has already been created. You can attest that the data is true, or that it is misinformation.</p>
        <div className='lc-paragraph-text' style={{ width: '60%' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
            <label style={{ textAlign: 'left' }}>
              Dataset Name:
            </label>
            <div>
              <span className="fancyx">x</span>
              <input
                className='sign-input-box'
                style={{ marginLeft: 'auto', width: '200px' }}
                value={this.state.newDatasetName}
                onChange={event => { this.setState({ newDatasetName: event.target.value }) }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
            <div style={{ textAlign: 'left' }}>
              CID:
            </div>
            <div>
              <span className="fancyx">x</span>
              <input
                className='sign-input-box'
                style={{ width: '320px' }}
                value={this.state.newDatasetCID}
                onChange={event => { this.setState({ newDatasetCID: event.target.value }) }}
              />
              <a
                href={`https://${this.state.newDatasetCID}.ipfs.w3s.link`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginLeft: '10px' }}
              >
                <img
                  src={ipfsImg}
                  alt="IPFS Link"
                  style={{ width: '15px', height: '15px' }}
                />
              </a>
            </div>
          </div>
          <br />
          <div style={{ textAlign: 'left' }}>
            Description:
          </div>
          <textarea
            className="multiline-input"
            value={this.state.newDatasetDescription}
            onChange={e => this.setState({ newDatasetDescription: e.target.value })}
            placeholder=""
          ></textarea>
        </div>

        <table style={{ borderCollapse: 'collapse', width: '60%', marginTop: '10px' }}>
          <tbody>
            <tr>
              <td style={{ width: '33%', textAlign: 'left' }} className='lc-trusted'>
                <input type="radio" id="trusted" name="trust" value='true' checked={this.state.newDataTrusted} onChange={() =>
                  this.setState({
                    newDataTrusted: true,
                  })}
                  hidden />
                <label htmlFor="trusted" className="radio-label"></label>Trusted
              </td>
              <td style={{ width: '33%' }} className='lc-not-trusted'>
                <input type="radio" id="notTrusted" name="trust" value='false' checked={!(this.state.newDataTrusted)} onChange={() =>
                  this.setState({
                    newDataTrusted: false,
                  })}
                  hidden />
                <label htmlFor="notTrusted" className="radio-label"></label> Not Trusted
              </td>
              <td className='blue-script-link-rightaligned' style={{ width: '33%' }}>
                <button className='blue-script-button'
                  onClick={this.createAttestation}>{'Attest >'}</button>
              </td>
            </tr>
          </tbody>
        </table>

        <p className="lc-success">{this.state.successText}</p>
        <p className="lc-error">{this.state.errorText}</p>
      </div>
    );
  }
}

export default SignCID;
