import React, { Component, useState } from 'react';
import CopyButtonImg from '../CopyButton.png';
import CopyButtonHoverImg from '../CopyButton_Active.png';
import CopyButtonPressedImg from '../CopyButton_pressed.png';
import {shortenAddress, CopyButton} from './summaryDisplayElements';

class CommunitiesTable extends Component {
    constructor(props){
        super(props);

        this.state = {
            term: '',
            numCommunities: this.props.numCommunities
        };
    }

    shortenAddress(address){
        return `${address.slice(0, 10)}...${address.slice(-5)}`;
    }

    render () {

        return(
            <div className='lc-paragraph-text'> 
                <h2>Registered Communities</h2>

                <table style={{ borderCollapse: 'collapse', width: '71%' }}>
                {/* <table style={{ border: "1px solid black", width: '80%' }}> */}
                <tbody>
                  {this.props.communityData.map((item, index) => (
                    <tr key={index}>
                      <td className="lc-paragraph-bold" style={{ width: '40%' }}>{item.description}</td>
                      <td className="lc-paragraph-text" style={{ width: '35%'  }}>{shortenAddress(item.address)} <CopyButton textToCopy={item.address} /></td>
                      <td style={{ width: '25%', textAlign: 'right'}}><a href={"community?address=".concat(item.address)} className="blue-script-link-rightaligned">{'View >'}</a></td>
                    </tr>
                  ))}
                </tbody>
              </table>


            </div>
            
        );
    };


};

export default CommunitiesTable;