import React, { Component, useState } from 'react';
import LiquiCert_Badge from '../LiquiCert_Badge.png';
import LiquiCert_Footer from '../LiquiCert_Footer.png';
import {shortenAddress, CopyButton, LinkButton} from './summaryDisplayElements';
import ipfsImg from '../IPFS.png';
import LoadingPreview from '../LoadingPreview.jpg'
import Checkbox from '../Checkbox.png';
import Xbox from '../Xbox.png';
import LiquiCert_Seal_Green from '../LiquiCert_Seal_Green.png';
import LiquiCert_Seal_Red from '../LiquiCert_Seal_Red.png';
import LiquiCert_Seal_Inactive from '../LiquiCert_Seal_Inactive.png';
import CertificationPathCard from './CertificationPathCard'


// const DisplayTrustPath = ({pathInfo, index, communityData}) => {

//     // console.log(pathInfo)
//     const trustPathTitle = `Trust Path #${index + 1}:`

//     //Message and styling for overall trust path
//     let trustBoxImage = Xbox;
//     let pathTrustValue = "Not Trusted ";
//     let pathTrustStyle = 'lc-not-trusted-small-superbold';
//     if (pathInfo.trusted){
//         trustBoxImage = Checkbox;
//         pathTrustValue = "Trusted ";
//         pathTrustStyle = 'lc-trusted-small-superbold';
//     }

//     // Message and styling for last path element
//     let attestationTrustValue = "Does Not Trust ";
//     let attestationTrustStyle = 'lc-not-trusted';
//     if (pathInfo.attestation.trusted){
//         attestationTrustValue = "Trusts ";
//         attestationTrustStyle = 'lc-trusted';
//         if (!(pathInfo.trusted)){attestationTrustStyle = 'lc-trusted-inactive';}
//     }

//     let trustSeal = LiquiCert_Seal_Red
//     if (pathInfo.trusted) {trustSeal = LiquiCert_Seal_Green}
//     if (pathInfo.trusted == null) {trustSeal = LiquiCert_Seal_Inactive}

//     // Track whether we've gone past a link in the trust chain that doesn't trust; affects styling later on
//     let passedNoTrust = false;

//     return (
//         <div>
//             <br />
//             <p className="lc-paragraph-bold-left">{trustPathTitle}</p>
//             <div style={{ width: '400px'}}>
//                 {pathInfo.validPath.map((item, index) => {
//                     if(!(pathInfo.validPath[index].trusted)){
//                         passedNoTrust = true}
//                     // console.log(index)
//                     // console.log(passedNoTrust)
//                     if (index == 0) {
//                         const returnMessage = `The ${getCommunityNameFromAddress(item.address, communityData)} community`
//                         return (
//                             <div style={{ textAlign: 'left'}}>
//                                 <span className="lc-paragraph-simple">{returnMessage}</span>
//                             </div>)
//                     } else {
//                         let trustValue = "Does Not Trust ";
//                         let trustStyle = 'lc-not-trusted';
//                         if (pathInfo.validPath[index-1].trusted){
//                             // console.log('Setting truthy style')
//                             trustValue = "Trusts ";
//                             trustStyle = 'lc-trusted';
//                             if(passedNoTrust) {
//                                 // console.log("triggering because passed no trust")
//                                     trustStyle = 'lc-trusted-inactive'}
//                             // console.log(trustStyle)
//                         }
//                         let whoMessage = ', who'
//                         return (
//                             <div style={{ textAlign: 'left'}}>
//                                 <span className={trustStyle}>{trustValue} </span>
//                                 <span className={"lc-paragraph-simple"}>{getCommunityNameFromAddress(item.address, communityData)}</span>
//                                 <span className={"lc-paragraph-simple"}>{whoMessage} </span>
//                             </div>
//                         )
//                     }
//                 })}
//                 <div style={{ textAlign: 'left'}}>
//                     <span className={attestationTrustStyle}>{attestationTrustValue} </span>
//                     <span className={"lc-paragraph-simple"}>{pathInfo.attestation.message.name}</span>
//                 </div>
//                 <div style={{display:'flex', marginTop:'10px'}}>
//                     <div>
//                         <img src={trustSeal} style={{width:'100px', height:'auto', paddingTop:'8px'}}></img>
//                     </div>
//                     <div>
//                         <div style={{ textAlign: 'left', display:'flex', marginLeft:'30px', paddingTop:'10px'}}>
//                             <img src={trustBoxImage} style={{width:'13px', height:'13px', marginRight:'5px'}}></img>
//                             <span className={"lc-paragraph-small-superbold"} style={{whiteSpace: 'pre'}}>{"The data is "}</span>
//                             <span className={pathTrustStyle} style={{whiteSpace: 'pre'}}>{pathTrustValue}</span>
//                             <span className={"lc-paragraph-small-superbold"} style={{whiteSpace: 'pre'}}>{"along this path"}</span>
//                         </div>
//                         <div style={{ textAlign: 'left', display:'flex', marginLeft:'30px', paddingTop:'10px'}}>
//                             <img src={Checkbox} style={{width:'13px', height:'13px', marginRight:'5px'}}></img>
//                             <span className={"lc-paragraph-small-superbold"} style={{whiteSpace: 'pre'}}>{"Path is valid"}</span>
//                         </div>
//                         <div style={{ textAlign: 'left', display:'flex', marginLeft:'30px', paddingTop:'10px'}}>
//                             <CopyButton textToCopy={JSON.stringify(pathInfo)} />
//                             <span className={"lc-paragraph-small-superbold"} style={{whiteSpace: 'pre', marginLeft:'5px'}}>{"Copy Proof"}</span>
//                         </div>
//                         <div style={{ textAlign: 'left', display:'flex', marginLeft:'30px', paddingTop:'10px'}}>
//                             <LinkButton proofPath={JSON.stringify(pathInfo)} />
//                             <span className={"lc-paragraph-small-superbold"} style={{whiteSpace: 'pre', marginLeft:'5px'}}>{"Link to Proof"}</span>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
//   };

// // Try to get the name. If there isn't a community name, just return the address
// function getCommunityNameFromAddress(address, communityData) {
//     let toReturn = shortenAddress(address);
//     communityData.forEach((item, index) =>{
//         // console.log(item)
//         if (item.address == address) {toReturn = item.description}
//     })
//     return toReturn
// }

class Attestation extends Component {

    constructor(props){
        super(props);

        this.state = {
            params: null,
            trustPathCID: null,
            pathRecord: null,
            srcaddress: '',
            pathstr: '',
            pathjson: null,
            datacid: '',
            contentInfo: [{name:'Loading data...', payload_cid: ''}],
            imgThumbnail:LoadingPreview,
            expandedRecord: [],
    //         trustFromProperties:{},
    //         trustPaths:[],
    //         trustPathMessage: '',
    //         disclaimer:'',
            communityData: [],
            title: 'loading...'
        }

        this.contentDetails = this.contentDetails.bind(this);

    }

    componentDidMount() {
        this.contentDetails();
        
    };

    // loadCommunityData(){
    //     fetch("/communityDetails")
    //         .then((res) => res.json())
    //         .then((data) => {
    //           this.setState({
    //             communityData: data
    //           })
    //           // console.log(data)
    //         });
    //   }

    // fetchImage = () => {
    //     console.log('trying to fetch')
    //     console.log(`address:${this.state.address} is the one`)

    //     fetch(`/screenshot?url=https://${this.state.address}.ipfs.w3s.link/`)
    //       .then((response) => response.blob())
    //       .then((blob) => {
    //         const imgThumbnail = URL.createObjectURL(blob);
    //         this.setState({ imgThumbnail });
    //       })
    //       .catch((error) => console.error("Error fetching image:", error));
    //   };
      

    async contentDetails(){
        const { location } = this.props;
        // console.log(location)
        const query = new URLSearchParams(location.search);
        const trustPathCID = await query.get('trustpath');
        console.log(`just fetched and ${trustPathCID} is the trust path cid`)
        this.setState({trustPathCID});

        // First load the trust path
        fetch("/getTrustPathByCID?path_CID=".concat(trustPathCID))
        .then((res) => res.json())
        .then((data) => {
            console.log(data[0])
            const pathjson = JSON.parse(data[0].pathstr);
            this.setState({
                pathRecord: data[0],
                pathjson,
                datacid: pathjson.attestation.payload_cid
            })

            // console.log(`data cid: ${pathjson.attestation.payload_cid}`)

            // Now load the attestation (don't trust the trust path because it's user data)
            fetch("/getAttestationByID?payload_CID=".concat(pathjson.attestation.payload_cid))
            .then((res) => res.json())
            .then((data) => {
            this.setState({
                contentInfo: data[0],
                expandedRecord: new Array(data.length).fill(0),
                title: data[0].name
            })
              console.log(data[0])
            });

            // Load the image preview
            fetch(`/cid-preview?cid=${pathjson.attestation.payload_cid}`)
            .then((response) => {
                if (response.status === 500) {
                    // Handle 500 Internal Server Error specifically
                    throw new Error('Server error');
                }
                // console.log(response)
                return response.blob()})
            .then((blob) => {
                const imgThumbnail = URL.createObjectURL(blob);
                this.setState({ imgThumbnail });
            })
            .catch((error) => console.error("Error fetching image:", error));
        
        });

        fetch("/communityDetails")
            .then((res) => res.json())
            .then((data) => {
              this.setState({
                communityData: data
              })
              // console.log(data)
            });

        

    //     // console.log('trying to fetch')
    //     // console.log(`trying to fetch from :${address}`)

    //     // fetch(`/screenshot?url=https://${address}.ipfs.w3s.link/`)
    //     // fetch(`https://cid-preview-api-5880826d85b2.herokuapp.com/preview?cid=${address}`)
    //     fetch(`/cid-preview?cid=${address}`)
    //       .then((response) => {
    //         if (response.status === 500) {
    //             // Handle 500 Internal Server Error specifically
    //             throw new Error('Server error');
    //         }
    //         // console.log(response)
    //         return response.blob()})
    //       .then((blob) => {
    //         const imgThumbnail = URL.createObjectURL(blob);
    //         this.setState({ imgThumbnail });
    //       })
    //       .catch((error) => console.error("Error fetching image:", error));
    };

    // viewDetails = (e) => {
    //     e.preventDefault(); // Prevent the default anchor link behavior
    //     console.log('Anchor tag clicked!');
        
    //   };

    // findTrustPath = () => {

    //     let queryString = `/findTrustPaths?communitySrc=${this.state.trustFromProperties.address}&dataTarget=${this.state.address}`
    //     // console.log(queryString)

    //     // let response = await fetch(queryString)
    //     // console.log(response)


    //     fetch(queryString)
    //     .then((res) => res.json())
    //     .then((data) => {
    //         let newTrustPathMessage = 'Searching for trust paths...'
    //         switch(data){
    //             case null:
    //                 newTrustPathMessage = `No trust paths were found between ${this.state.trustFromProperties.description} and ${this.state.contentInfo[0].name}.`
    //                 break;
    //             default:
    //                 switch(data.length){
    //                     case 1: 
    //                         newTrustPathMessage = `One trust path was found between ${this.state.trustFromProperties.description} and ${this.state.contentInfo[0].name}.`
    //                         break;
    //                     case 2: newTrustPathMessage = `Two trust paths were found between ${this.state.trustFromProperties.description} and ${this.state.contentInfo[0].name}.`
    //                         break;
    //                     case 3: newTrustPathMessage = `Three trust paths were found between ${this.state.trustFromProperties.description} and ${this.state.contentInfo[0].name}.`
    //                         break;
    //                     default: newTrustPathMessage = `${data.length} trust paths were found between ${this.state.trustFromProperties.description} and ${this.state.contentInfo[0].name}.`
    //                         break;
    //                 }
    //             break;
    //         }
    //         this.setState({
    //             trustPaths: data,
    //             trustPathMessage: newTrustPathMessage,
    //             disclaimer:`This is a demo on Ethereum Sepolia testnet with information contributed by users. Please do not rely on this information, particularly if you have not validated the address of the ${this.state.trustFromProperties.description} community.`
    //         })
    //     });

    //     this.loadCommunityData()
        
    //   };

    //   setFromCommunity = (event) => {
    //     // console.log(event.target.value)
    //     this.setState({ 
    //         trustFromProperties: JSON.parse(event.target.value)
    //      });
    //     // console.log(event.target.value)
    //   }
      

    render(){

        // console.log(this.props.communityData)
        let attestationExplain = `Several LiquiCert contributors attested to this dataset. See what they say about it here.`;
        let pathmessage = ''
        // if (this.state.pathRecord) {pathmessage = JSON.stringify(JSON.parse(this.state.pathRecord.pathstr))}
        if(this.state.pathjson) {pathmessage = JSON.stringify(this.state.contentInfo)}
        
        // console.log(this.state.contentInfo.length)
        // switch(this.state.contentInfo.length){
        //     case 1:
        //         attestationExplain = `One LiquiCert contributor attested to this dataset. See what they say about it here.`;
        //         break;
        //     case 2:
        //         attestationExplain = `Two LiquiCert contributors attested to this dataset. See what they say about it here.`;
        //         break;
        // }
        
            // console.log(this.state.contentInfo)
            return (
                <div>
                    <div className='wide-transparent-plate'>
                        <div className='about'>
                            <h1 className='blue-script-title'>{this.state.title}</h1>
                            <hr class="title-line"></hr>
                            <p className='attestation-subtitle'>Map the flow of trust between <br /> this dataset and a community <br /> you find credible</p>
                            <div style={{marginTop:'50px', display: 'flex', alignItems: 'flex-start', width:'900px'}}>
                                    {this.state.pathjson != null && (
                                        <CertificationPathCard pathInfo={this.state.pathjson} assetName={this.state.contentInfo.name} communityData={this.state.communityData} pathCID={this.state.trustPathCID} key={1} />
                                    )}
                                {/* border: '2px solid #ccc', */}
                                <div style={{ display: 'inline-block', width: '500px', verticalAlign: 'top'}}>
                                    <img src={this.state.imgThumbnail} width='250px' height='auto' alt="Screenshot" style={{border:"1px solid #ccc"}}/>
                                    <table style={{width: '70%', paddingTop: '10px'}}>
                                        <tbody>
                                            <tr style={{height: '10px'}}>
                                                {/* style={{border:'1px solid #ddd'}} */}
                                                <td style={{width: '30%'}} className='preview-bold' >Name:</td>
                                                <td className='preview-text'>{this.state.contentInfo.name}</td>
                                            </tr>
                                            <tr style={{height: '10px'}}>
                                                <td style={{width: '30%'}} className='preview-bold'>Description:</td>
                                                <td className='preview-text'>{this.state.contentInfo.description}</td>
                                            </tr>
                                            <tr style={{height: '10px'}}>
                                                <td style={{width: '30%'}} className='preview-bold'>Data CID:</td>
                                                <td className='preview-text'>{shortenAddress(this.state.contentInfo.payload_cid)}
                                                    <div style={{ float: 'right'}}><a href={`https://${this.state.contentInfo.payload_cid}.ipfs.w3s.link`} target="_blank" rel="noopener noreferrer" >
                                                            <img src={ipfsImg} alt="IPFS Link" style={{ width: '15px', height: '15px', marginRight:'4px'}} /></a>
                                                            <CopyButton textToCopy={this.state.contentInfo.payload_cid} />
                                                        </div>
                                                </td>
                                            </tr>
                                            <tr style={{height: '10px'}}>
                                                <td style={{width: '30%'}} className='preview-bold'>Attestation Type:</td>
                                                <td className='preview-text'>{"Ethereum wallet signature"}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <img src="/img/SectionSeparator.svg" alt="Section Separator" class="section-divider-squiggly" style={{marginTop: '10px'}}/>
                                    <div className='lc-paragraph-text'> <h3>Trust Flow</h3></div>
                                    <p className='lc-middle-paragraph-smaller'>LiquiCert maps the flow of trust from a community to this dataset. Pick a community below to figure out whether they trust the data. If you don’t trust any of the communities listed, then make your own!</p>
                                    


                                </div>
                            </div>
                            <img src={LiquiCert_Footer} className='footer-style' />
                        </div>
                    </div>
                </div>
            );
    }

};

export default Attestation;