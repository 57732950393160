import React, { Component, useState } from 'react';
import CopyButtonImg from '../CopyButton.png';
import CopyButtonHoverImg from '../CopyButton_Active.png';
import CopyButtonPressedImg from '../CopyButton_pressed.png';
import LinkButtonDefault from '../Link_default.png';
import LinkButtonHover from '../Link_bright.png';
import LinkButtonPressed from '../Link_pressed.png';
import SpriteButon from '../sprite.png';
import SpritePressed from '../sprite_pressed.png';
import SpriteHover from '../sprite_hover.png';


function shortenAddress(address){
  if(!(typeof(address)=='string')){return ''}
  if(!(address.length>16)){return address}
  return `${address.slice(0, 10)}...${address.slice(-5)}`;
}


const LinkButton = ({ proofPath }) => {
  const [imgSrc, setImgSrc] = useState(LinkButtonDefault);

      // Handle mouse over
  const handleMouseOver = () => setImgSrc(LinkButtonHover);

  // Handle mouse out
  const handleMouseOut = () => setImgSrc(LinkButtonDefault);
  const handleMouseDown = () => setImgSrc(LinkButtonPressed);
  const handleMouseUp = () => setImgSrc(LinkButtonDefault);



  const handleClick = () => {

      fetch("/savePath", {
        method: "POST",
        body: JSON.stringify({"proofPath": proofPath}),
        headers: {"Content-type": "application/json; charset=UTF-8"}
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data)
          if (document.hasFocus()) {
            navigator.clipboard.writeText(`/attestation?trustpath=${data}`)
            window.open(`/attestation?trustpath=${data}`, '_blank');
          }
        })
        // .then(() => this.loadTable())
      // navigator.clipboard.writeText(textToCopy)
  };
  

return (
    <img src={imgSrc}
    className='copy-image-small'
    onMouseOver={handleMouseOver}
    onMouseOut={handleMouseOut}
    onClick={handleClick}
    onMouseDown={handleMouseDown}
    onMouseUp={handleMouseUp} />
  );
};

const CopyButton = ({ textToCopy }) => {
    const [imgSrc, setImgSrc] = useState(CopyButtonImg);
  
        // Handle mouse over
    const handleMouseOver = () => setImgSrc(CopyButtonHoverImg);
  
    // Handle mouse out
    const handleMouseOut = () => setImgSrc(CopyButtonImg);
    const handleMouseDown = () => setImgSrc(CopyButtonPressedImg);
    const handleMouseUp = () => setImgSrc(CopyButtonImg);
  
  
  
    const handleCopy = () => {
        navigator.clipboard.writeText(textToCopy)
    };
  
  return (
      <img src={imgSrc}
      className='copy-image-small'
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={handleCopy}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp} />
    );
  };

  const CopySprite = ({ textToCopy }) => {
    const [imgSrc, setImgSrc] = useState(SpriteButon);
  
        // Handle mouse over
    const handleMouseOver = () => setImgSrc(SpriteHover);
  
    // Handle mouse out
    const handleMouseOut = () => setImgSrc(SpriteButon);
    const handleMouseDown = () => setImgSrc(SpritePressed);
    const handleMouseUp = () => setImgSrc(SpriteButon);
  
  
  
    const handleCopy = () => {
        navigator.clipboard.writeText(textToCopy)
    };

    return (
      <img src={imgSrc}
      className='copy-image-small'
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={handleCopy}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp} />
    );
  };

export {shortenAddress, CopyButton, LinkButton, CopySprite};